<template>
  <div class="page page--no-header">
    <div class="container">
        <div class="row mb--sm--9 mb--md--20 mt--sm--9 mt--md--20">
            <div class="col col--sm--12 col--md--6 text--sm--center d--sm--flex justify-content--sm--center align-items--sm--center flex-direction--sm--column mb--sm--9 mb--md--0">
                <ChangePasswordIcon class="mb--sm--2"/>
                <div class="logo-branch">Đăng ký</div>
            </div>
            <div class="col col--sm--12 col--md--6">
              <form action="" @submit.prevent="formSubmitHandle">
                <HdInput
                  v-model="phone"
                  type="text"
                  label="Số điện thoại:"
                  placeholder="Số điện thoại"
                  helpText="Kiểu số, bắt đầu từ số 0 và độ dài là 10"
                  required
                >
                  <template v-slot:icon><InputNewPasswordIcon/></template>
                </HdInput>
                <HdInput
                  v-model="password"
                  type="password"
                  label="Mật khẩu:"
                  placeholder="Mật khẩu"
                  helpText="Ít nhất 8 kí tự"
                  required
                >
                  <template v-slot:icon><InputNewPasswordIcon/></template>
                </HdInput>
                <HdInput
                  v-model="confirmPassword"
                  type="password"
                  label="Xác nhận mật khẩu:"
                  placeholder="Nhập lại mật khẩu"
                  helpText="Ít nhất 8 kí tự"
                  required
                >
                  <template v-slot:icon><InputConfirmNewPasswordIcon/></template>
                </HdInput>
                <div class="text--sm--center mt--sm--8">
                  <HdButton nativeType="submit" fullWidth>Xác nhận</HdButton>
                </div>
              </form>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// Impport Components
import HdInput from '@/components/HdInput'
import HdButton from '@/components/HdButton'

// Import Icon
import ChangePasswordIcon from '@/assets/change-password.svg'
import InputNewPasswordIcon from '@/assets/input-new-password-icon.svg'
import InputConfirmNewPasswordIcon from '@/assets/input-confirm-new-password-icon.svg'

export default {
  components: {
    HdInput,
    HdButton,
    ChangePasswordIcon,
    InputNewPasswordIcon,
    InputConfirmNewPasswordIcon
  },
  data () {
    return {
      phone: '',
      password: '',
      confirmPassword: ''
    }
  },
  methods: {
    formSubmitHandle: function (event) {
      const { phone, password, confirmPassword } = this
      const phonePattern = /^0\d{9}$/
      if (!phone.match(phonePattern)) {
        return this.$alert('Số điện thoại là kiểu số, bắt đầu từ số 0 và độ dài là 10!', 'Thông báo', {
          confirmButtonText: 'OK'
        })
      }

      if (password !== confirmPassword) {
        return this.$notify.error({
          duration: 1000,
          title: 'Lỗi!',
          message: this.$t('changePassword.confirmPassword')
        })
      }

      if (password.length < 8 || password.length > 12) {
        return this.$alert('Mật khẩu mới phải có độ dài ít nhất 8 ký tự và tối đa 12 ký tự!', 'Thông báo', {
          confirmButtonText: 'OK'
        })
      }

      this.$store.dispatch('auth/register', { username: phone, password: password }).then(response => {
        this.$notify({
          duration: 1000,
          title: 'Thành công!',
          message: this.$t('register.success'),
          type: 'success'
        })
      }, error => {
        if (error && error.response) {
          this.$notify.error({
            duration: 3000,
            title: 'Lỗi!',
            message: error.response.data.error.message.value || error.message
          })
        }
      })
    }
  }
}
</script>
